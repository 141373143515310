import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { TempLayout } from "./layouts/TempLayout";
import { BasicLayout } from "./layouts/BasicLayout";
//import { Main } from "./pages/Main";
import { NopageLayout } from "./layouts/NopageLayout";
import { Requisites } from "./pages/Requisites";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <TempLayout />,
    },
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          path: "main",
          element: <Navigate to="/" />,
          //element: <Main />,
        },
        {
          path: "requisites",
          element: <Requisites />,
        },
      ],
    },
    {
      path: "*",
      element: <NopageLayout />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
