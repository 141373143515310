//import { BasketIcon } from "../Basket/BasketIcon/BasketIcon";
//import { Navigation } from "../Navigation/Navigation";
import { Logo } from "../ui/Logo/Logo";
//import { IconWrapper } from "../ui/Wrappers/IconWrapper/IconWrapper";
//import { UserIcon } from "../User/UserIcon/UserIcon";
import "./Header.css";

export const Header = (): JSX.Element => {
  return (
    <header className="header">
      <Logo />
      {/*<Navigation />
      <IconWrapper>
        <UserIcon />
        <BasketIcon />
      </IconWrapper>*/}
    </header>
  );
};
